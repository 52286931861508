import template from './whatsapp-message-edit.html';
import Message from '../../../../../core-ui/ui-ps-message-manager/src/models/Message.js';

/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for whatsapp message edit
 */
class ComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.tabs = ko.observableArray([
			{
				title: 'Text',
				page_id: 'text',
				icon: 'fa-solid fa-info-circle'
			},
			{
				title: 'Attachments',
				page_id: 'attachments',
				icon: 'fa-solid fa-upload'
			},
			{
				title: 'Properties',
				page_id: 'advanced',
				icon: 'fa-solid fa-arrow-progress'
			}
		]);
		this.current_page_id = ko.observable('text');

		this.disabled = ko.observable();
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.templates = ko.observableArray([]);
		this.show_save_btn = ko_helper.safe_observable(params.show_save_btn || false);
		this.show_send_btn = ko_helper.safe_observable(params.show_send_btn || false);

		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true'); // hide or show addresses input
		this.init();

		this.message.subscribe(() => this.set_message());
	}

	async init()
	{
		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (!this.message().addressList() || this.message().addressList().length == 0)
			this.message().set({"address-full":[{ role: 'from', type: 'whatsapp', name: this.message().channel().default_from_address||'' }]});

		this.message().getPropertyObservable('body').subscribe((data) => {
			let str = data;
			if(data.length > 30)
				str = data.slice(0, 30)+'...'

			this.message().setPropertyValue('preview', str);
		});

		this.set_message();
		await this.getTemplates();
	}

	/* called when message changes */
	async set_message()
	{
		if (this.message())
		{
			let exist = false;
			for (let a of this.message().addressList())
				if(a.role() === 'to')
					exist = true;

			if(!exist)
			{
				this.message().addressList().push({
					address_uuid: null,
					type: 'whatsapp',
					name: ko.observable(''),
					role: ko.observable('to')
				});
			}

			this.message().setPropertyValue('preview', this.message().getPropertyValue('body'));
		}
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}

	async onContentFileUpload (event)
	{
		await this.message().uploadContent(event.target.files);
		await this.message().fetch(['content.property']);
	}

	deleteContent (content)
	{
		let updatedContentList = this.message().contentList().filter(x =>
			x.name() !== content.name());
		this.message().contentList(updatedContentList);
	}

	remove_address (address)
	{
		this.message().removeAddress(address);
	}

	async btnSave ()
	{
		await this.message().saveContentBodies();
		await this.message().save();
		this.params.saveCallback();
	}

	async btnSend ()
	{
		if (!(await Grape.alerts.confirm({ type: 'warning', title: 'Confirm',  message: 'Are you sure you want to send this message?' })))
			return;

		await this.message().saveContentBodies();
		await this.message().setPropertyValue('envelopedate', new Date().toISOString());
		await this.message().save();
		await this.message().send();
		this.params.sendCallback();
	}

	async getTemplates ()
	{
		try 
		{
			const result = await Grape.fetches.getJSON(`/api/record`, { 
				schema: 'messages', 
				table: 'v_templates',
				filter: [
					{
						field: 'driver_name',
						operand: '=',
						value: 'Email'
					}
				]
			});

			if (result.status == 'OK')
				this.templates(result.records || []);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	name: 'whatsapp-message-edit',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};