import template from './whatsapp-message-view.html';
import Message from '../../../../../core-ui/ui-ps-message-manager/src/models/Message.js';

/**
 * @kind component
 * @class ComponentViewModel
 * @description Component for whatsapp message edit
 */
class ComponentViewModel
{
	constructor (params)
	{
		this.editor = null;

		this.params = params;

		this.current_page_id = ko.observable('text');
		this.message_input = ko_helper.safe_observable(params.message);
		this.message = ko.observable();
		this.collapsed = ko_helper.safe_observable(params.collapsed || true);

		this.addresses = ko_helper.safe_observable(params.addresses ? params.addresses : 'true'); // hide or show addresses input
		this.init();
	}

	async init()
	{
		if (!(this.message_input() instanceof Message))
		{
			const msg = new Message();
			await msg.set(this.message_input());
			this.message(msg);
		}
		else
			this.message(this.message_input());

		if (!this.message().addressList() || this.message().addressList().length == 0)
			this.message().set({ "address-full":[{ role:'from', type:'whatsapp', name:this.message().channel().default_from_address || '' }] });

		this.message().getPropertyObservable('body').subscribe((data) => {
			let str = data;
			if(data.length > 30)
				str = data.slice(0, 30)+'...'

			this.message().setPropertyValue('preview', str);
		});

		await this.set_message();

		this.message.subscribe(() => this.set_message());
	}

	/* called when message changes */
	async set_message()
	{
		if (this.message())
			this.message().setPropertyValue('preview', this.message().getPropertyValue('body'));
	}

	load_tab (page_id)
	{
		this.current_page_id(page_id);
	}

	get_contact ()
	{
		let contact = this.message().addressList().find(a => a.role() === 'from')?.name();

		return contact;
	}

	get_date ()
	{
		let date = this.message().getPropertyValue('envelopedate');

		return date;
	}
}

export default {
	name: 'whatsapp-message-view',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};